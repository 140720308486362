import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Utils
import { sortBy } from 'lodash';
import { useRouter } from 'next/router';
import Collapse, { Panel } from 'rc-collapse';
import motion from '../../../../src/utils/motion';

// Icons
import ChevronDown from '../../../../public/icons/chevron_down.svg';
import getLanguageId from '../../../../src/utils/i18n/getLanguageId';
import cn from '../../../../src/utils/cn';
import sanitizeHtml from '../../../../src/utils/wysiwyg/sanitizeHtml';

function QuestionsAnswers({ questions, white }) {
  const { locale } = useRouter();
  const [activeKey, setActiveKey] = useState(null);

  const expandIcon = () => (
    <ChevronDown
      width={12}
      height={12}
      className="rc-collapse-custom-icon"
    />
  );

  return (
    <Collapse
      accordion
      activeKey={activeKey}
      onChange={(key) => setActiveKey(key)}
      openMotion={motion}
      expandIcon={expandIcon}
      className={cn([
        'questions_answers',
        white ? 'white' : '',
      ])}
    >
      {sortBy(questions, 'order').map(({
        title, description,
      }, key) => (
        title && description ? (
          <Panel
            key={key}
            header={title[getLanguageId(locale)]?.length ? title[getLanguageId(locale)] : (title[2] || '')}
          >
            <div
              className="bullet_list wysiwyg black"
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(
                  description[getLanguageId(locale)]?.length
                    ? description[getLanguageId(locale)]
                    : (description[2] || ''),
                ),
              }}
            />
          </Panel>
        ) : null
      ))}
    </Collapse>
  );
}

QuestionsAnswers.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.shape({})),
  white: PropTypes.bool,
};

QuestionsAnswers.defaultProps = {
  questions: [],
  white: false,
};

export default QuestionsAnswers;
