import React, { useEffect, useState, useCallback } from 'react';
import Image from 'next/image';

// Utils
import { useMediaQuery } from 'react-responsive';
import { useRouter } from 'next/router';
import { range } from 'lodash';
import useEmblaCarousel from 'embla-carousel-react';
import packagesTypes from '../../../src/enum/packagesTypes';
import searchFromParams from '../../../src/utils/search/searchFromParams';
import { useAppContext } from '../../../src/store/AppContext';
import useWeekId from '../../../src/hooks/useWeekId';

// Images
import ArrowRight from '../../../public/icons/nav-right.svg';
import ArrowLeft from '../../../public/icons/nav-left.svg';
import CircleArrow from '../../../public/icons/circle_arrow.svg';

import styles from './CardsSlider.module.scss';

function CardsSlider({ slides, moreLabel }) {
  const router = useRouter();
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const { getWeekIdFromDate } = useWeekId();

  const [{ players }] = useAppContext();

  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: false,
    align: 'start',
    containScroll: slides.length,
    draggable: isMobile,
  });

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

  const scrollPrev = useCallback(() => emblaApi && emblaApi.scrollPrev(), [emblaApi]);
  const scrollNext = useCallback(() => emblaApi && emblaApi.scrollNext(), [emblaApi]);

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setPrevBtnEnabled(emblaApi.canScrollPrev());
    setNextBtnEnabled(emblaApi.canScrollNext());
  }, [emblaApi]);

  useEffect(() => {
    if (!emblaApi) return;
    emblaApi.on('select', onSelect);
    onSelect();
  }, [emblaApi, onSelect]);

  const handleSearch = (slide) => {
    const type = packagesTypes.find((ptype) => ptype.id === slide.typeId);
    const todayWeek = getWeekIdFromDate(new Date());
    const weeksRange = range(todayWeek + 1, todayWeek + 5);

    if (weeksRange?.length && type) {
      searchFromParams(type.id, weeksRange, router, slide.themeId, players);
    }
  };

  return (
    <div className={styles.slider_wrapper}>
      <div className={`${styles.nav} flex a-center j-end m-t-10`}>
        <p className={`${styles.more} ${prevBtnEnabled ? styles.hidden : ''}`}>{moreLabel}</p>
        <button
          type="button"
          className={`${styles.navButton} ${prevBtnEnabled ? '' : styles.hidden}`}
          onClick={scrollPrev}
        >
          <ArrowLeft className={styles.navIcon} />
        </button>
        <button type="button" className={styles.navButton} onClick={scrollNext} disabled={!nextBtnEnabled}>
          <ArrowRight className={styles.navIcon} />
        </button>
      </div>

      <div className={styles.slider}>
        {slides.length ? (
          <div className={`${styles.embla} p-b-30 mobile-p-b-0`} ref={emblaRef}>
            <div className={styles.embla_container}>
              {slides.map((slide, i) => (
                slide.image && (
                <div className={styles.embla_slide} key={i}>
                  <button
                    type="button"
                    className={styles.slide}
                    onClick={() => handleSearch(slide)}
                  >
                    <Image
                      loader={(img) => img?.src}
                      unoptimized
                      src={slide.image}
                      layout="fill"
                      objectFit="cover"
                      alt={slide.title}
                      priority
                    />
                    <div className={`${styles.slide_title} flex a-center j-spb`}>
                      {slide.title}
                      <CircleArrow className={styles.search_arrow} width={35} height={35} />
                    </div>
                  </button>
                </div>
                )
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default CardsSlider;
