import React, {
  useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';

// Utils
import { sortBy } from 'lodash';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useMediaQuery } from 'react-responsive';
import useEmblaCarousel from 'embla-carousel-react';
import cn from '../../../src/utils/cn';

// Icons
import ArrowRight from '../../../public/icons/nav-right.svg';
import ArrowLeft from '../../../public/icons/nav-left.svg';

// Components
import YoutubeEmbed from '../../organisms/YoutubeEmbed/YoutubeEmbed';

import styles from './VideoTestimonies.module.scss';
import getLanguageId from '../../../src/utils/i18n/getLanguageId';

function VideoTestimonies({ testimonies }) {
  const { t } = useTranslation();
  const { locale } = useRouter();
  const isTablet = useMediaQuery({ maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: 'start',
    speed: 20,
  });

  const [currentVisibleSlide, setCurrentVisibleSlide] = useState(0);

  const scrollPrev = useCallback(() => emblaApi && emblaApi.scrollPrev(), [emblaApi]);
  const scrollNext = useCallback(() => emblaApi && emblaApi.scrollNext(), [emblaApi]);
  const scrollTo = useCallback((index) => emblaApi && emblaApi.scrollTo(index), [emblaApi]);

  useEffect(() => {
    if (emblaApi) {
      emblaApi.on('select', () => setCurrentVisibleSlide(emblaApi.selectedScrollSnap()));
    }
  }, [emblaApi]);

  const handleScrollNext = (index) => {
    if ((index + 1) === testimonies?.length) {
      // last one : scroll to first slide
      scrollTo(0);
    } else {
      scrollNext();
    }
  };

  const getNavigation = useCallback((index) => (testimonies?.length > 1 ? (
    <div className={cn([styles.nav_wrapper, 'flex a-center m-t-auto'])}>
      {index === 0 ? (
        <button
          type="button"
          className={cn([
            'text-c-white fs-14 fw-300 c-pointer',
          ])}
          onClick={() => handleScrollNext(index)}
        >
          {t('home.testimonies.slide.viewMore')}
        </button>
      ) : (
        <button
          type="button"
          className={cn([
            styles.nav,
            'flex center c-pointer',
          ])}
          onClick={scrollPrev}
        >
          <ArrowLeft width={35} height={35} />
        </button>
      )}

      <button
        type="button"
        className={cn([
          styles.nav,
          'm-l-20 flex center c-pointer',
        ])}
        onClick={() => handleScrollNext(index)}
      >
        <ArrowRight width={35} height={35} />
      </button>
    </div>
  ) : null), [scrollPrev, scrollNext]);

  return testimonies?.length ? (
    <div className={styles.wrapper}>
      <div className={styles.slider}>
        <div className={styles.embla} ref={emblaRef}>
          <div className={cn([styles.embla_container, 'flex a-stretch'])}>
            {sortBy(testimonies, 'order').map(({ id, title, videoUrl }, index) => (
              <div
                key={id}
                className={cn([
                  styles.embla_slide,
                  'flex a-stretch',
                ])}
              >
                <div className={cn([styles.inner_slide, 'flex d-col a-stretch'])}>
                  <div className="flex a-stretch j-spb h-100 tablet-d-col tablet-a-start tablet-j-start tablet-m-b-30">
                    <div className={cn([styles.slide_content, 'flex d-col j-center'])}>
                      <h3 className="uppercase title-underline fs-14 m-b-40 tablet-m-b-20 mobile-m-b-40">
                        {t('home.testimonies.slide.title')}
                      </h3>
                      <p className="fs-34 fw-500 m-b-40 tablet-m-b-20 mobile-m-b-40 tablet-fs-24 mobile-fs-20">
                        {title[getLanguageId(locale)]}
                      </p>

                      {!isTablet ? getNavigation(index) : null}
                    </div>

                    <div className={cn([styles.slide_media, 'flex d-col j-center'])}>
                      <YoutubeEmbed
                        id={id}
                        youtubeUrl={videoUrl}
                        title={title[getLanguageId(locale)]}
                        hoverLabel={isMobile ? null : t('home.testimonies.slide.play')}
                        iframeCanBeLoaded={currentVisibleSlide === index}
                      />
                    </div>
                  </div>

                  {isTablet ? getNavigation(index) : null}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

VideoTestimonies.propTypes = {
  testimonies: PropTypes.arrayOf(PropTypes.shape({})),
};

VideoTestimonies.defaultProps = {
  testimonies: [],
};

export default VideoTestimonies;
