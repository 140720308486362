import { omit } from 'lodash';
import Micromodal from 'micromodal';
import bookingStep from '../../enum/bookingStep';
import modalConfig from '../modalConfig';

const handleSearch = (values, router) => {
  router.push({
    pathname: bookingStep.route.stage,
    query: {
      ...omit(values, 'players'),
      ...values.players,
    },
  });
};

const searchFromParams = (typeId, weeksRange, router, themeId, storedPlayers, eventId) => {
  const values = {
    weeks: weeksRange,
    weekend: false,
    players: {
      child: typeId === 1 ? 1 : 0,
      adult: typeId === 2 ? 1 : 0,
      baby: typeId === 3 ? 1 : 0,
    },
    hosting: true,
    theme: themeId || null,
    eventId: eventId || null,
  };

  if (storedPlayers?.length) {
    // if user already has a cart in progress, ask before launching a new search
    Micromodal.show('modal-reset-search', {
      ...modalConfig,
      onClose: (modal) => {
        if (modal.dataset.reset) handleSearch(values, router);
      },
    });
  } else if (values) {
    handleSearch(values, router);
  }
};

export default searchFromParams;
