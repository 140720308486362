import { useMediaQuery } from 'react-responsive';

const useImage = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const isTablet = useMediaQuery({ maxWidth: 1024 });
  const isSmallLaptop = useMediaQuery({ maxWidth: 1200 });
  const isLargeLaptop = useMediaQuery({ maxWidth: 1440 });

  const cloudImageUrl = process.env.NEXT_PUBLIC_CLOUDIMAGE_URL;

  const getWidthBySize = (widths) => {
    let width = widths.default;

    if (isLargeLaptop && widths.largeLaptop) {
      width = widths.largeLaptop;
    }
    if (isSmallLaptop && widths.smallLaptop) {
      width = widths.smallLaptop;
    }
    if (isTablet && widths.tablet) {
      width = widths.tablet;
    }
    if (isMobile && widths.mobile) {
      width = widths.mobile;
    }

    const isRetina = window.devicePixelRatio > 1;

    return isRetina ? width * 2 : width;
  };

  const getImageSrc = ({ fileId, widths }) => {
    const basePath = fileId ? `${cloudImageUrl}${fileId}` : null;
    if (!basePath) return '';

    const mediaUrl = new URL(basePath);

    if (widths?.default) {
      mediaUrl.searchParams.append('width', getWidthBySize(widths));
    }
    mediaUrl.searchParams.append('sharp', 1);

    return mediaUrl?.href || '';
  };

  return {
    getImageSrc,
  };
};

export default useImage;
