import React from 'react';
import Image from 'next/image';

// Utils
import { useTranslation } from 'next-i18next';
import { useMediaQuery } from 'react-responsive';

// Images
import HowImg1 from '../../../public/images/home/how_img1.jpg';
import HowImg2 from '../../../public/images/home/how_img2.jpg';
import MobileRefine from '../../../public/images/home/refine.svg';
import MobileCustomize from '../../../public/images/home/customize.svg';
import MobileExceptionalPlace from '../../../public/images/home/exceptional_place.svg';

import styles from './HowItWorks.module.scss';

function HowItWorks() {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const isTablet = useMediaQuery({ maxWidth: 1024 });

  return (
    <section className={styles.section_wrapper}>
      <div className={`${styles.section} max-wrapper flex`}>
        <div className={styles.section_container}>
          <div>
            <p className="uppercase fs-14 fw-400 title-underline m-b-20">{t('home.how.subtitle')}</p>

            {!isMobile ? (
              <>
                <h2 className="text-c-primary fw-700 fs-34">{t('home.how.title')}</h2>
                <p
                  className={`${styles.how_paragraph} text-c-primary m-t-10 fw-400`}
                  dangerouslySetInnerHTML={{ __html: t('home.how.paragraph') }}
                />
              </>
            ) : null}
          </div>
          <ul className={styles.list}>
            <li className={styles.list_item}>
              <span>01</span>
              <div>
                <p className="text-c-primary fs-20 fw-500 m-b-5 mobile-fs-16">{t('home.how.list.refineSearch')}</p>
                <p className="fs-14 text-c-black-700 fw-400">{t('home.how.list.refineSearch.details')}</p>
              </div>

              {isMobile ? (
                <MobileRefine className={styles.mobileIcon} />
              ) : null}
            </li>
            <li className={styles.list_item}>
              <span>02</span>
              <div>
                <p className="text-c-primary fs-20 fw-500 m-b-5 mobile-fs-16">{t('home.how.list.customize')}</p>
                <p className="fs-14 text-c-black-700 fw-400">{t('home.how.list.customize.details')}</p>
              </div>

              {isMobile ? (
                <MobileCustomize className={styles.mobileIcon} />
              ) : null}
            </li>
            <li className={styles.list_item}>
              <span>03</span>
              <div>
                <p className="text-c-primary fs-20 fw-500 m-b-5 mobile-fs-16">{t('home.how.list.exceptionalPlace')}</p>
                <p className="fs-14 text-c-black-700 fw-400">{t('home.how.list.exceptionalPlace.details')}</p>
              </div>

              {isMobile ? (
                <MobileExceptionalPlace className={styles.mobileIcon} />
              ) : null}
            </li>
          </ul>
        </div>
      </div>

      <div className={`${styles.images} flex`}>
        <div style={{ height: '463px' }}>
          <Image src={HowImg1} width={384} height={463} alt="Mouratoglou experience" />
        </div>

        {!isTablet ? (
          <div className="m-l-20" style={{ height: '463px' }}>
            <Image className={styles.img2} src={HowImg2} width={384} height={463} alt="Mouratoglou experience" />
          </div>
        ) : null}
      </div>
    </section>
  );
}

HowItWorks.propTypes = {};

export default HowItWorks;
