import { useEffect, useState } from 'react';
import Head from 'next/head';

// Translations
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

// Utils
import { useRouter } from 'next/router';
import Micromodal from 'micromodal';
import { isEmpty, sortBy } from 'lodash';
import modalConfig from '../src/utils/modalConfig';
import useStorage from '../src/hooks/useStorage';
import getLanguageId from '../src/utils/i18n/getLanguageId';

// Components
import HeadSection from '../components/home/Head/Head';
import HowItWorks from '../components/home/HowItWorks/HowItWorks';
import HighlightedEvent from '../components/home/HighlightedEvent/HighlightedEvent';
import ThemesCards from '../components/home/ThemesCards/ThemesCards';
import Resort from '../components/home/Resort/Resort';
import VideoTestimonies from '../components/home/VideoTestimonies/VideoTestimonies';
import QuestionsWidget from '../components/organisms/FAQ/QuestionsWidget/QuestionsWidget';
import SearchBar from '../components/organisms/SearchBar/SearchBar';
import Modal from '../components/organisms/Modal/Modal';
import Button from '../components/atoms/Button/Button';

// Services
import themesService from '../src/services/themesService';
import eventsService from '../src/services/eventsService';
import offersService from '../src/services/offersService';
import weeksService from '../src/services/weeksService';
import packagesService from '../src/services/packagesService';
import faqService from '../src/services/faqService';
import testimoniesService from '../src/services/testimoniesService';

// Services
import configService from '../src/services/configService';

import styles from '../styles/pages/Home.module.scss';

export default function Home({
  themes, events, offers, weeks, packages, testimonies, questions,
}) {
  const { locale } = useRouter();
  const { t } = useTranslation(['common']);
  const { getSessionItem, setSessionItem } = useStorage();

  const [config, setConfig] = useState(null);

  useEffect(() => {
    configService.getConfig().then((res) => {
      if (res?.data) setConfig(res.data);
    });
  }, []);

  useEffect(() => {
    if (config?.homeModalShow && isEmpty(getSessionItem('show-once'))) {
      Micromodal.show('modal-show-once', modalConfig);
      setSessionItem('show-once', 'seen');
    }
  }, [config]);

  return (
    <div>
      <Head>
        <title>{t('app.title')}</title>
        <meta name="description" content={t('app.title')} />
      </Head>

      <main className={styles.home}>
        <HeadSection config={config} />
        <SearchBar offers={offers} weeks={weeks} packages={packages} />
        <HowItWorks />
        <HighlightedEvent events={events} />
        <ThemesCards themes={themes} />
        <Resort />
        <VideoTestimonies testimonies={testimonies} />
        <QuestionsWidget questions={questions} />
      </main>

      {config?.homeModalShow ? (
        <Modal
          id="modal-show-once"
          title={config.homeModalTitles[getLanguageId(locale)]}
          maxWidth="440px"
        >
          <div className={styles.show_once_modal}>
            <h3 className="fs-20 fw-500">
              {config.homeModalSubtitles[getLanguageId(locale)]}
            </h3>
            <p className="fs-16 fw-400 m-t-30">
              {config.homeModalBodies[getLanguageId(locale)]}
            </p>

            {config?.homeModalEmailLabels && config?.homeModalEmail ? (
              <a
                href={`mailto:${config.homeModalEmail}`}
                className="d-inline-block m-t-20 text-c-secondary fw-500"
              >
                {config.homeModalEmailLabels[getLanguageId(locale)]}
              </a>
            ) : null}

            {config?.homeModalSubmits ? (
              <div className="flex a-center j-spb m-t-30 mobile-d-col">
                <Button
                  aria-label="Close modal"
                  data-micromodal-close
                  text="uppercase"
                  full
                >
                  {config.homeModalSubmits[getLanguageId(locale)]}
                </Button>
              </div>
            ) : null}
          </div>
        </Modal>
      ) : null}
    </div>
  );
}

export async function getServerSideProps({ locale }) {
  const themes = await themesService.getThemes();
  const themesData = themes?.data?.themes?.length ? sortBy(themes.data.themes, ['order']).reverse() : [];

  const events = await eventsService.getEvents();
  const eventsData = events ? events.data : [];

  let sortedEvents = [];
  if (eventsData?.events?.length) sortedEvents = sortBy(eventsData.events, ['ordinal']).reverse();
  sortedEvents = sortedEvents?.length ? sortedEvents.filter((ev) => ev.active) : [];

  const offers = await offersService.getOffers();
  const offersData = offers ? offers.data : [];

  const weeks = await weeksService.getWeeks();
  const weeksData = weeks ? weeks.data : [];

  const packages = await packagesService.getPackages();
  const packagesData = packages ? packages.data : [];

  const testimonies = await testimoniesService.getTestimonies({ count: 4 });
  const testimoniesData = testimonies ? testimonies.data : [];

  const questions = await faqService.getQuestions({ count: 4 });
  const questionsData = questions ? questions.data : [];

  return {
    props: {
      ...(await serverSideTranslations(
        locale || 'fr',
        ['common'],
      )),
      themes: themesData || [],
      events: sortedEvents || [],
      offers: offersData?.offers ? offersData.offers : [],
      weeks: weeksData?.weeks ? weeksData.weeks : {},
      packages: packagesData?.packages ? packagesData.packages : [],
      testimonies: testimoniesData?.testimonies ? testimoniesData.testimonies : [],
      questions: questionsData?.questions ? questionsData.questions : [],
    },
  };
}
