import React, {
  forwardRef, useEffect, useImperativeHandle, useState,
} from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';

// Utils
import useEmblaCarousel from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';

import styles from './ImagesSlider.module.scss';

const ImagesSlider = forwardRef(({
  images, autoPlay, showDots, className, setCurrentIndex,
}, ref) => {
  const [emblaRef, embla] = useEmblaCarousel(
    { loop: true },
    autoPlay ? [Autoplay({ delay: 5000, stopOnInteraction: false })] : [],
  );

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);

  const onSelect = () => {
    if (!embla) return;
    setSelectedIndex(embla.selectedScrollSnap());
  };

  useEffect(() => {
    setCurrentIndex(selectedIndex);
  }, [selectedIndex]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    setScrollSnaps(embla.scrollSnapList());
    embla.on('select', onSelect);
  }, [embla]);

  useImperativeHandle(ref, () => ({
    next() {
      if (embla) embla.scrollNext();
    },
    prev() {
      if (embla) embla.scrollPrev();
    },
    scrollTo(index, jump) {
      if (embla) embla.scrollTo(index, { jump: jump || false });
    },
  }));

  return (
    <div className={`${styles.slider} ${className || ''}`}>
      {images?.length ? (
        <div className={`${styles.embla}`} ref={emblaRef}>
          <div className={styles.embla_container}>
            {images.map((image, i) => (
              image && (
              <div className={styles.embla_slide} key={i}>
                <div className={styles.slide}>
                  <Image
                    loader={(img) => img?.src}
                    unoptimized
                    src={image}
                    layout="fill"
                    objectFit="cover"
                    alt={`slide-${i}`}
                  />
                </div>
              </div>
              )
            ))}
          </div>
        </div>
      ) : null}

      {showDots ? (
        <div className={styles.dots}>
          {scrollSnaps.map((_, index) => (
            <div key={index} className={`${styles.dot} ${index === selectedIndex ? styles.active_dot : ''}`} />
          ))}
        </div>
      ) : null}
    </div>
  );
});

ImagesSlider.displayName = 'ImagesSlider';

ImagesSlider.propTypes = {
  images: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string])).isRequired,
  autoPlay: PropTypes.bool,
  showDots: PropTypes.bool,
  className: PropTypes.string,
  setCurrentIndex: PropTypes.func,
};

ImagesSlider.defaultProps = {
  autoPlay: false,
  showDots: false,
  className: null,
  setCurrentIndex: () => {},
};

export default ImagesSlider;
