import React, {
  forwardRef, useEffect, useImperativeHandle, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';

// Utils
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'next-i18next';
import { useMediaQuery } from 'react-responsive';

// Icons
import CloseIcon from '../../../public/icons/cross.svg';
import InfoIcon from '../../../public/icons/info_inverted.svg';
import CheckIcon from '../../../public/icons/circle_check_small.svg';
import ChevronLeft from '../../../public/icons/chevron_left.svg';

// Components
import Tooltip from '../../atoms/Tooltip/Tooltip';
import Button from '../../atoms/Button/Button';

import styles from './InputWithPanel.module.scss';
import cn from '../../../src/utils/cn';
import { useAppContext } from '../../../src/store/AppContext';
import lockBodyScroll from '../../../src/utils/lockBodyScroll';

const InputWithPanel = forwardRef(
  (
    {
      picto,
      label,
      values,
      withCloseBtn,
      align,
      panelStyle,
      children,
      dropdownArrow,
      tooltip,
      tooltipId,
      showTooltipOnInit,
      panelTitle,
      className,
      classNameOpened,
      hasAutoScroll,
      withCheckProgress,
      smallTopLabel,
      displayJoinedValues,
      isRequired,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const isTablet = useMediaQuery({ maxWidth: 1024 });
    const [, stateDispatch] = useAppContext();

    const [panelIsOpened, setPanelIsOpened] = useState(false);
    const infoTooltip = useRef(null);

    useEffect(() => {
      setTimeout(() => {
        if (showTooltipOnInit && infoTooltip?.current) ReactTooltip.show(infoTooltip.current);
      }, 1000);
    }, [infoTooltip]);

    useImperativeHandle(ref, () => ({
      openPanel() {
        setPanelIsOpened(true);
      },
    }));

    const refPanel = useRef(null);
    const buttonRef = useRef('buttonRef');

    const calcInputSize = () => {
      let width = 'initial';

      setTimeout(() => {
        if (buttonRef?.current?.clientWidth) {
          buttonRef.current.style.maxWidth = width;
          buttonRef.current.style.minWidth = width;
          const { clientWidth } = buttonRef.current;
          width = `${(clientWidth > 200 ? clientWidth : 200) + 1}px`;
          buttonRef.current.style.maxWidth = width;
          buttonRef.current.style.minWidth = width;
        }
      }, 500);
    };

    useEffect(() => {
      calcInputSize();
      window.addEventListener('resize', calcInputSize, true);
      return () => window.removeEventListener('resize', calcInputSize, true);
    }, []);

    const handleClickOutside = (event) => {
      if (isMobile) return;

      const hasClickedInPanel = refPanel?.current && refPanel.current.contains(event.target);
      const hasClickedOnInput = buttonRef?.current && buttonRef.current.contains(event.target);

      if (!hasClickedInPanel && !hasClickedOnInput) {
        setPanelIsOpened(false);
      }
    };

    useEffect(() => {
      ReactTooltip.rebuild();

      if (!values?.length) {
        calcInputSize();
      }

      document.addEventListener('click', handleClickOutside, true);
      return () => document.removeEventListener('click', handleClickOutside, true);
    }, [values]);

    useEffect(() => {
      // AUTOSCROLL : on opening, scroll to panel (desktop only)
      if (
        panelIsOpened
        && hasAutoScroll
        && !isMobile
        && !isTablet
        && buttonRef?.current?.getBoundingClientRect()?.top > 100
      ) {
        buttonRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, [panelIsOpened]);

    useEffect(() => {
      stateDispatch({ type: 'SET_IS_HEADER_FOLDED', payload: panelIsOpened && isMobile });
      stateDispatch({ type: 'SET_IS_BOOKING_NEXT_FOLDED', payload: panelIsOpened && isMobile });
      lockBodyScroll(panelIsOpened && isMobile);
    }, [isMobile, panelIsOpened]);

    return (
      <div className={styles.input_wrapper}>
        <button
          ref={buttonRef}
          type="button"
          className={cn([
            styles.input,
            panelIsOpened ? styles.input_selected : '',
            panelIsOpened && classNameOpened ? classNameOpened : '',
            dropdownArrow ? styles.with_arrow : '',
            smallTopLabel && values?.length > 0 ? styles.with_small_label : '',
            className || '',
            'flex a-center fs-14 fw-500',
          ])}
          onClick={() => setPanelIsOpened(!panelIsOpened)}
        >
          {smallTopLabel ? (
            <span className={cn([styles.small_label, values?.length > 0 ? styles.visible : ''])}>{smallTopLabel}</span>
          ) : null}

          {picto ? (
            <div
              className={cn([
                styles.input_picto,
                values?.length > 0 && withCheckProgress ? styles.filled : '',
              ])}
            >
              {picto}
              <CheckIcon width={20} height={20} viewBox="0 0 16 16" />
            </div>
          ) : null}
          {values.length > 0 ? (
            <div className={cn([styles.input_value, 'flex a-center'])}>
              {displayJoinedValues ? (
                <span className={styles.input_value_text}>{values.join(', ')}</span>
              ) : (
                <>
                  <span className={styles.input_value_text}>{values[0]}</span>

                  {values.length > 1 ? (
                    <span
                      data-tip={`${values.filter((_, i) => i > 0).join('<br/>')}`}
                      data-for="showAllWeeks"
                      className="more-dates right"
                    >
                      {`+ ${values.length - 1}`}
                      <Tooltip id="showAllWeeks" />
                    </span>
                  ) : null}
                </>
              )}
            </div>
          ) : (
            <span className={styles.input_label}>{label}</span>
          )}
          {tooltip && tooltipId ? (
            <>
              <span ref={infoTooltip} className="m-l-10 p-t-5" data-tip={tooltip} data-for={tooltipId}>
                <InfoIcon />
              </span>
              <Tooltip id={tooltipId} />
            </>
          ) : null}
        </button>
        <div
          ref={refPanel}
          className={cn([
            styles.panel,
            panelIsOpened ? styles.panel_opened : '',
            align ? styles[align] : '',
            panelStyle || '',
            'flex d-col a-stretch',
          ])}
        >
          <div
            className={cn([
              'flex d-col a-stretch flex-auto',
            ])}
          >
            {withCloseBtn && !isMobile ? (
              <button
                type="button"
                onClick={() => setPanelIsOpened(false)}
                className={styles.close_panel}
              >
                <CloseIcon width={16} height={16} />
              </button>
            ) : null}

            {isMobile ? (
              <p
                className={cn([
                  styles.modal_title,
                  'flex a-center fs-18 fw-700',
                  !withCloseBtn ? 'text-center' : '',
                ])}
              >
                {withCloseBtn && isMobile ? (
                  <button
                    type="button"
                    onClick={() => setPanelIsOpened(false)}
                    className={cn([styles.close_panel_mobile, 'flex center m-l-20 m-r-20'])}
                  >
                    <ChevronLeft />
                  </button>
                ) : null}
                <span className="d-block flex-auto mobile-p-r-20">
                  {panelTitle || label}
                </span>
              </p>
            ) : null}

            {children}

            {isMobile ? (
              <div className={styles.apply_mobile}>
                <Button
                  full
                  text="uppercase"
                  onClick={() => setPanelIsOpened(false)}
                  disabled={isRequired && !values?.length}
                >
                  {t('common.apply')}
                </Button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  },
);

InputWithPanel.displayName = 'InputWithPanel';

InputWithPanel.propTypes = {
  picto: PropTypes.shape({}),
  label: PropTypes.string.isRequired,
  panelTitle: PropTypes.string,
  children: PropTypes.node.isRequired,
  values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  withCloseBtn: PropTypes.bool,
  align: PropTypes.oneOf(['right', 'center']),
  panelStyle: PropTypes.string,
  dropdownArrow: PropTypes.bool,
  tooltip: PropTypes.string,
  tooltipId: PropTypes.string,
  className: PropTypes.string,
  classNameOpened: PropTypes.string,
  hasAutoScroll: PropTypes.bool,
  withCheckProgress: PropTypes.bool,
  smallTopLabel: PropTypes.string,
  displayJoinedValues: PropTypes.bool,
  isRequired: PropTypes.bool,
};

InputWithPanel.defaultProps = {
  picto: null,
  values: [],
  withCloseBtn: false,
  panelTitle: null,
  align: null,
  panelStyle: null,
  dropdownArrow: false,
  tooltip: null,
  tooltipId: null,
  className: null,
  classNameOpened: null,
  hasAutoScroll: false,
  withCheckProgress: false,
  smallTopLabel: null,
  displayJoinedValues: false,
  isRequired: false,
};

export default InputWithPanel;
