/* eslint-disable @next/next/no-img-element */
import React, {
  useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';

// Utils
import cn from '../../../src/utils/cn';

// Image
import YoutubePlayIcon from '../../../public/images/youtube_play.svg';

import styles from './YoutubeEmbed.module.scss';

const staticYoutubeBase = 'https://www.youtube.com/embed/';

const thumbnailQualities = {
  max: 'maxresdefault',
  hq: 'hqdefault',
  default: '',
};

function YoutubeEmbed({
  youtubeUrl, title, hoverLabel, iframeCanBeLoaded,
}) {
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [thumbnailQuality, setThumbnailQuality] = useState(thumbnailQualities.max);

  useEffect(() => {
    if (!iframeCanBeLoaded) {
      setVideoPlaying(false);
    }
  }, [iframeCanBeLoaded]);

  const youtubeUrlParser = (url) => {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[7].length === 11) ? match[7] : false;
  };

  const getSrcWithOptions = useCallback(() => {
    const embedId = youtubeUrlParser(youtubeUrl);
    if (!embedId) return null;

    const url = new URL(`${staticYoutubeBase}${embedId}`);

    url.searchParams.append('modestbranding', '1');
    url.searchParams.append('controls', '3');
    url.searchParams.append('autoplay', videoPlaying ? '1' : '0');

    return url.href;
  }, [youtubeUrl, videoPlaying]);

  const handleLoadMaxResolution = (e) => {
    const intrinsicSize = e?.target?.naturalWidth;

    if (intrinsicSize > 0) {
      // High quality
      if (thumbnailQuality === thumbnailQualities.max && intrinsicSize !== 1280) {
        setThumbnailQuality(thumbnailQualities.hq);
      }

      // Default quality
      if (thumbnailQuality === thumbnailQualities.hq && intrinsicSize !== 480) {
        setThumbnailQuality(thumbnailQualities.default);
      }
    }
  };

  return youtubeUrl && youtubeUrlParser(youtubeUrl) && getSrcWithOptions() ? (
    <div className={cn([styles.container, videoPlaying ? '' : styles.not_playing])}>
      {iframeCanBeLoaded ? (
        <iframe
          width="100%"
          height="100%"
          src={getSrcWithOptions()}
          title={title}
          allow="autoplay; fullscreen"
        />
      ) : null}

      <img
        role="presentation"
        src={`http://img.youtube.com/vi/${youtubeUrlParser(youtubeUrl)}/${thumbnailQuality}.jpg`}
        alt={title}
        className={cn([styles.thumbnail, videoPlaying ? styles.hidden : ''])}
        onClick={() => setVideoPlaying(true)}
        onLoad={handleLoadMaxResolution}
      />

      <YoutubePlayIcon
        width={72}
        height={72}
        className={cn([styles.play_icon, videoPlaying ? styles.hidden : ''])}
      />

      {hoverLabel ? (
        <p className={cn([styles.hover_label, 'text-c-white fs-14'])}>{hoverLabel}</p>
      ) : null}
    </div>
  ) : null;
}

YoutubeEmbed.propTypes = {
  youtubeUrl: PropTypes.string.isRequired,
  title: PropTypes.string,
  hoverLabel: PropTypes.string,
  iframeCanBeLoaded: PropTypes.bool,
};

YoutubeEmbed.defaultProps = {
  title: '',
  hoverLabel: null,
  iframeCanBeLoaded: true,
};

export default YoutubeEmbed;
