const sanitizeHtml = (raw) => {
  if (!raw?.length) return '';

  return raw
    .replaceAll('<br>', '')
    .replaceAll('<p></p>', '')
    .replaceAll('<p><br></p>', '');
};

export default sanitizeHtml;
