import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Utils
import { useTranslation } from 'next-i18next';

// Components
import RadioGroup from '../../../molecules/RadioGroup/RadioGroup';

function Hosting({ formik }) {
  const { t } = useTranslation();

  const [choice, setChoice] = useState(null);
  const choices = [{ name: 'yes', label: t('common.yes') }, { name: 'no', label: t('search.hosting.maybe') }];

  useEffect(() => {
    formik.setFieldValue('hosting', choice && choice === 'yes');
  }, [choice]);

  return (
    <div className="p-15 mobile-p-r-20 mobile-p-l-20">
      <p className="m-b-15 fw-500">{t('search.hosting.title')}</p>
      <p className="fs-14 fw-400">{t('search.hosting.description')}</p>

      <div className="m-t-25">
        <RadioGroup
          groupName="hosting"
          entries={choices}
          selected={choice}
          setSelected={setChoice}
          large
        />
      </div>
    </div>
  );
}

Hosting.propTypes = {
  formik: PropTypes.shape({}).isRequired,
};

export default Hosting;
