import React from 'react';

// Utils
import { useTranslation } from 'next-i18next';
import { useMediaQuery } from 'react-responsive';

// Images
import ResortSlide1 from '../../../public/images/home/resort_slide_1.jpg';
import ResortSlide2 from '../../../public/images/home/how_img1.jpg';
import ResortSlide4 from '../../../public/images/home/how_img2.jpg';

// Components
import ImagesSlider from '../../organisms/ImagesSlider/ImagesSlider';

import styles from './Resort.module.scss';

function Resort() {
  const { t } = useTranslation();
  const images = [ResortSlide1, ResortSlide2, ResortSlide4];
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <>
      <section className={styles.resort_wrapper}>
        <div className={`${styles.resort_section} max-wrapper flex a-center j-spb mobile-a-stretch`}>
          <div className={styles.content_block}>
            <div className={styles.content_text}>
              <h3 className="uppercase title-underline fs-14 m-b-20 mobile-m-b-15">{t('home.resort.subtitle')}</h3>
              <h2 className="fs-34 fw-700 text-c-primary m-b-40 l-h-1-2 lh-1-2 mobile-fs-20 mobile-fw-500">
                {!isMobile ? (
                  <span>{t('home.resort.title')}</span>
                ) : (
                  <span>{t('home.resort.title').replace('...', '')}</span>
                )}
              </h2>

              {!isMobile ? (
                <>
                  <h2 className="fs-24 fw-700 text-c-primary">{t('home.resort.title2')}</h2>
                  <p
                    className="m-t-25 fw-400"
                    dangerouslySetInnerHTML={{ __html: t('home.resort.paragraph') }}
                  />
                </>
              ) : null}

            </div>

            <div className={`${styles.numbers_wrapper} m-t-60`}>
              <ul className={`${styles.numbers_list} flex a-center j-spb mobile-a-stretch`}>
                <li className={styles.number}>
                  <span>34</span>
                  <span>{t('home.resort.numbers.one')}</span>
                </li>
                <li className={styles.number}>
                  <span>4</span>
                  <span>{t('home.resort.numbers.two')}</span>
                </li>
                <li className={styles.number}>
                  <span>1</span>
                  <span>{t('home.resort.numbers.three')}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className={styles.slider_wrapper}>
          <div className={styles.slider_max}>
            <ImagesSlider
              className={styles.slider}
              images={images}
              autoPlay
              showDots
            />
          </div>
        </div>
      </section>

      {isMobile ? (
        <div className="m-b-10 p-r-20 p-l-20 m-t-20">
          <p
            className="fw-400"
            dangerouslySetInnerHTML={{ __html: t('home.resort.paragraph') }}
          />
        </div>
      ) : null}
    </>
  );
}

Resort.propTypes = {};

export default Resort;
