import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';

// Utils
import { useTranslation } from 'next-i18next';
import { useMediaQuery } from 'react-responsive';

// Components
import QuestionsAnswers from '../QuestionsAnswers/QuestionsAnswers';
import Button from '../../../atoms/Button/Button';

import styles from './QuestionsWidget.module.scss';

function QuestionsWidget({ questions }) {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return questions?.length ? (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_inner}>
        <h4 className="uppercase title-underline fs-14 m-b-20">
          {t('home.faq.title')}
        </h4>
        <h3 className="text-c-primary fs-34 fw-700 lh-1-2 m-b-40 tablet-fs-24 mobile-fs-20">
          {t('home.faq.subtitle')}
        </h3>

        <QuestionsAnswers questions={questions} />

        <div className="flex center m-t-25">
          <Link href="/help" passHref>
            <Button text="uppercase" full={isMobile}>
              {t('home.faq.viewMore')}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  ) : null;
}

QuestionsWidget.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.shape({})),
};

QuestionsWidget.defaultProps = {
  questions: [],
};

export default QuestionsWidget;
