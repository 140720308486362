import * as yup from 'yup';

const searchFormSchema = yup.object().shape({
  weeks: yup.array().of(yup.number()).min(1).required(),
  weekend: yup.bool().required(),
  hosting: yup.bool().required(),
  players: yup.object().shape({
    baby: yup.number()
      .when(['child', 'adult'], {
        is: (child, adult) => (child === 0 && adult === 0),
        then: yup.number().min(1),
        otherwise: yup.number(),
      }),
    child: yup.number()
      .when(['baby', 'adult'], {
        is: (baby, adult) => (baby === 0 && adult === 0),
        then: yup.number().min(1),
        otherwise: yup.number(),
      }),
    adult: yup.number()
      .when(['baby', 'child'], {
        is: (baby, child) => (baby === 0 && child === 0),
        then: yup.number().min(1),
        otherwise: yup.number(),
      }),
  }, [
    ['child', 'adult'],
    ['baby', 'adult'],
    ['baby', 'child'],
  ]),
});

export default searchFormSchema;
