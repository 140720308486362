import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Utils
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import useImage from '../../../src/hooks/useImage';
import getLanguageId from '../../../src/utils/i18n/getLanguageId';

// Components
import CardsSlider from '../../organisms/CardsSlider/CardsSlider';

import styles from './ThemesCards.module.scss';

function ThemesCards({ themes }) {
  const { t } = useTranslation();
  const { locale } = useRouter();

  const { getImageSrc } = useImage();

  const [slides] = useState(themes?.length ? themes.map((theme) => ({
    title: theme.name[getLanguageId(locale)],
    image: getImageSrc({
      fileId: theme.thumbnailPath,
      widths: {
        default: 294,
        mobile: 687,
      },
    }),
    typeId: theme.typeId,
    themeId: theme.id,
  })) : []);

  return (
    <section className={styles.themes_wrapper}>
      <div className="max-wrapper">
        <h3 className={`${styles.subtitle} uppercase title-w-underline fs-14`}>{t('home.themes.subtitle')}</h3>
        <h2 className={`${styles.mainTitle} fw-500 m-t-40 mobile-m-t-15`}>{t('home.themes.title')}</h2>

        <CardsSlider slides={slides} moreLabel={t('home.themes.moreThemes')} />
      </div>
    </section>
  );
}

ThemesCards.propTypes = {
  themes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default ThemesCards;
