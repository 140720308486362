import React from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';

// Utils
import { useRouter } from 'next/router';
import { addDays, isSameYear } from 'date-fns';
import { range } from 'lodash';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'next-i18next';
import searchFromParams from '../../../src/utils/search/searchFromParams';
import packagesTypes from '../../../src/enum/packagesTypes';
import { useAppContext } from '../../../src/store/AppContext';
import getLanguageId from '../../../src/utils/i18n/getLanguageId';
import sanitizeHtml from '../../../src/utils/wysiwyg/sanitizeHtml';
import useWeekId from '../../../src/hooks/useWeekId';
import useLocalizedDate from '../../../src/hooks/useLocalizedDate';
import useImage from '../../../src/hooks/useImage';

// Images
import CircleArrow from '../../../public/icons/circle_arrow.svg';

// Components
import Button from '../../atoms/Button/Button';

import styles from './HighlightedEvent.module.scss';

function HighlightedEvent({ events }) {
  const { t } = useTranslation();
  const router = useRouter();
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const [{ players }] = useAppContext();
  const { getDateFromWeekId } = useWeekId();
  const { getDayMonthDate, getFullDate } = useLocalizedDate();
  const { getImageSrc } = useImage();

  const getDateLabel = (start, end) => {
    const endDate = addDays(end, 6); // end is on sunday
    const startDateLabel = isSameYear(start, endDate) ? getDayMonthDate(start) : getFullDate(start);
    const endDateLabel = getFullDate(endDate);

    return `${t('common.from')} ${startDateLabel} ${t('common.to')} ${endDateLabel}`;
  };

  const handleSearch = (event) => {
    const type = packagesTypes.find((ptype) => ptype.id === event.typeId);
    const weeksRange = event.startWeekId && event.endWeekId ? range(event.startWeekId, event.endWeekId + 1) : null;

    if (weeksRange?.length && type) {
      searchFromParams(type.id, weeksRange, router, null, players, event.id);
    }
  };

  return (
    events?.length ? events.map((event, i) => (
      <section key={i} className={`${styles.event_wrapper} ${(i + 1) % 2 ? '' : styles.event_wrapper_odd}`}>
        <div className={
            `${(i + 1) % 2 ? '' : 'd-reverse'} max-wrapper flex a-center mobile-d-col-reverse mobile-a-start`
          }
        >
          <div
            role="presentation"
            onClick={isMobile ? () => handleSearch(event) : () => {}}
            className={styles.img_container}
          >
            <Image
              loader={(img) => img?.src}
              unoptimized
              src={getImageSrc({
                fileId: event.illustrationPath,
                widths: {
                  default: 630,
                  tablet: 400,
                  mobile: 720,
                },
              })}
              alt={event.title[getLanguageId(router.locale)]}
              layout="fill"
              objectFit="cover"
            />
            <div
              className={
                `${styles.img_legend} ${(i + 1) % 2 ? '' : styles.img_legend_odd} flex mobile-j-spb mobile-a-center`
              }
            >
              <div>
                <h3 className="fs-24 fw-500 m-b-10">
                  {event.imageTitle[getLanguageId(router.locale)]}
                </h3>
                <p>
                  {getDateLabel(
                    getDateFromWeekId(event.startWeekId),
                    getDateFromWeekId(event.endWeekId),
                  )}
                </p>
              </div>

              {isMobile ? (
                <CircleArrow style={{ minWidth: '50px' }} className="m-l-30" />
              ) : null}
            </div>
          </div>

          <div className={`${styles.event_block} ${(i + 1) % 2 ? '' : styles.event_block_odd}`}>
            <h3 className="uppercase title-underline fs-14 m-b-20">
              {t('home.event.subtitle')}
            </h3>
            <h2 className="fs-34 text-c-primary fw-700 m-b-15 lh-1-2 mobile-fs-20 mobile-m-b-30">
              {event.title[getLanguageId(router.locale)]}
            </h2>

            {event.description?.[getLanguageId(router.locale)] && !isMobile ? (
              <div className="wysiwyg bullet_list">
                <div dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(event.description[getLanguageId(router.locale)]),
                }}
                />
              </div>
            ) : null}

            {!isMobile ? (
              <div className="m-t-40">
                <Button
                  onClick={() => handleSearch(event)}
                  text="uppercase"
                >
                  {t('home.event.cta')}
                </Button>
              </div>
            ) : null}

          </div>
        </div>
      </section>
    )) : null
  );
}

HighlightedEvent.propTypes = {
  events: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default HighlightedEvent;
