import React from 'react';
import PropTypes from 'prop-types';

// Utils
import { useTranslation } from 'next-i18next';

// Components
import InputNumber from '../../../molecules/InputNumber/InputNumber';

import styles from './Players.module.scss';

function Players({ formik }) {
  const { t } = useTranslation();

  return (
    <>
      <ul className={styles.players}>
        <li className={styles.player}>
          <div className="flex a-center j-spb">
            <p>
              {t('search.players.adult')}
            </p>
            <InputNumber
              fieldName="players.adult"
              name="adult"
              value={formik.values.players.adult}
              onChange={formik.handleChange}
              setFieldValue={formik.setFieldValue}
            />
          </div>
        </li>
        <li className={styles.player}>
          <div className="flex a-center j-spb">
            <p>
              {t('search.players.child')}
              <span className="d-block fs-12 text-c-black-600 m-t-5 fw-400">
                {t('search.child.age')}
              </span>
            </p>
            <InputNumber
              fieldName="players.child"
              name="child"
              value={formik.values.players.child}
              onChange={formik.handleChange}
              setFieldValue={formik.setFieldValue}
            />
          </div>
        </li>
        <li className={styles.player}>
          <div className="flex a-center j-spb">
            <p>
              {t('search.players.baby')}
              <span className="d-block fs-12 text-c-black-600 m-t-5 fw-400">
                {t('search.baby.age')}
              </span>
            </p>
            <InputNumber
              fieldName="players.baby"
              name="baby"
              value={formik.values.players.baby}
              onChange={formik.handleChange}
              setFieldValue={formik.setFieldValue}
            />
          </div>
        </li>
      </ul>

      {formik.values.players.adult + formik.values.players.child + formik.values.players.baby > 1 ? (
        <p className={styles.warning} dangerouslySetInnerHTML={{ __html: t('search.multiplayers.warning') }} />
      ) : null}
    </>
  );
}

Players.propTypes = {
  formik: PropTypes.shape({}).isRequired,
};

export default Players;
